<template>
  <div class="container-flex mx-auto">
    <div class="list-container">

      <!-- CounterpartList -->
      <base-list :items="counterpartItems"
                 :fields=getFields()
                 :sort-by-prop="sortBy"
                 :sort-desc-prop="true"
                 :totalItems="totalItems"
                 :slots="templates"
                 :loading="loading"
                 :asyncFilter="filterList"
                 @page-changed="onPageSelection"
      >

        <template slot="title">
          <div id="title-21126951" class="title">
            {{ staticText.counterpartsDisplayName }}
          </div>
        </template>
        <template #name="row">
          <div id="name-21251258"
               class="d-flex align-items-center"
          >
            <component
              :is="getCrmLogo(row.cell.crm_service)"
              class="crm-logo crm-logo__slate mr-2"
            />
            <span>
              <i :class="appInfo.getCounterpartIcon(row.cell.type)"></i>
            </span>
            <span class="ml-2">
              {{ row.cell.name }}
            </span>
          </div>
        </template>
        <template #last_call_date="row">
          <bao-date-time-display :value="row.cell.last_call_date"></bao-date-time-display>
        </template>
        <template #direct_link="row">
          <div id="direct-link-211281556"
               class="d-flex"
          >
            <b-button :href="row.cell.direct_link"
                      target="_blank"
                      class="direct-link"
            >
              {{ staticText.goToDirectLinkLabel }}
            </b-button>
          </div>
        </template>
        <template #actions="row">
          <div id="actions-211281557"
               class="d-flex"
          >
            <!-- show conversations button for counterpart -->
            <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->
            <b-button :id="'show-conversation-button-202103181246-'+row.cell.id"
                      variant="secondary"
                      class="mr-3 font-size-14 py-1"
                      @click.stop="handleShowConversations(row.cell.id)"
            >
              {{ staticText.showConversationsText }}
            </b-button>

            <!-- delete button for counterpart -->
            <bao-delete-button
              :id="'counterpart-delete-button-202103181246-'+row.cell.id"
              :url="appInfo.apiUrl+row.cell.id"
              @deleted="getCounterpartItems"
            >
              {{ staticText.warningModalText1 }}
              {{ staticText.warningModalText2 }}
            </bao-delete-button>
          </div>
        </template>
      </base-list>
    </div>
  </div>
</template>

<script>
import appInfo from "./index"
import axios from "axios"
import BaseList from "../base/BaseList"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { mapMutations } from "vuex"
import debounce from "lodash/debounce"
import BaoDateTimeDisplay from "@/apps/base/BaoDateTimeDisplay"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"

export default {
  name: "CounterpartList",
  mixins: [CrmLogoMixin],
  components: {
    BaseList,
    BaoDeleteButton,
    BaoDateTimeDisplay
  },
  data () {
    return {
      appInfo,
      axios,
      counterpartItems: [],
      selectedCounterpart: null,
      sortBy: "last_call_date",
      templates: [
        { name: "name", field: "name" },
        { name: "direct_link", field: "direct_link" },
        { name: "actions", field: "actions" },
        { name: "last_call_date", field: "last_call_date" }
      ],
      totalItems: null,
      loading: false,
      staticTextDefault: {
        warningModalText1: "Deleting a counterpart is an irreversible action.",
        warningModalText2: "Are you sure you want to delete the counterpart?",
        nameLabel: "Name",
        typeLabel: "Type",
        directLinkLabel: "Link",
        lastCallDateLabel: "Last Conversation Date",
        actionsLabel: "Actions",
        counterpartsDisplayName: "Counterparts",
        showConversationsText: "Show Conversations",
        goToDirectLinkLabel: "Go to CRM"
      }
    }
  },
  methods: {
    ...mapMutations({
      setError: "globalError/setError"
    }),
    onPageSelection (pageNumber) {
      this.$router.push({ query: { ...this.$route.query, currentPage: pageNumber } })
    },
    showDeleteModal (item) {
      this.selectedCounterpart = item
      this.$bvModal.show("del-warning-modal-202012281419")
    },
    getCounterpartItems (url, params = {}) {
      if (!url) {
        this.counterpartItems = []
      }
      params.page = this.$route.query.currentPage ? this.$route.query.currentPage : 1
      params.page_size = this.$route.query.page_size ? this.$route.query.page_size : 10
      this.loading = true
      return new Promise((resolve, reject) => {
        this.appInfo.getCounterpartItems(url, { params }).then(response => {
          this.counterpartItems = response.data.results
          this.totalItems = response.data.count
          this.loading = false
          resolve()
        },
        error => {
          this.loading = false
          this.setError(error)
          reject(error)
        })
      })
    },
    getFields () {
      return [
        { key: "name", label: this.staticText.nameLabel, sortable: true },
        { key: "type", label: this.staticText.typeLabel },
        { key: "direct_link", label: this.staticText.directLinkLabel },
        { key: "last_call_date", label: this.staticText.lastCallDateLabel, sortable: true },
        { key: "actions", label: this.staticText.actionsLabel }
      ]
    },
    handleShowConversations (counterpartId) {
      this.$router.push({ path: "/calls", query: { counterparts__id: counterpartId } })
    },
    filterList (filterValue) {
      this.handleInput("any_contains", filterValue)
    },
    handleInput (key, value) {
      const query = { ...this.$route.query }
      if (!value) {
        delete (query[key])
      } else {
        query[key] = value
      }
      query.currentPage = "1"
      this.$router.push({ query })
    },
    searchCounterparts () {
      const that = this
      debounce(
        function () {
          that.getCounterpartItems(null, { ...that.$route.query })
        },
        150
      )()
    }
  },
  watch: {
    "$route.query" () {
      this.searchCounterparts()
    }
  },
  created () {
    this.getCounterpartItems()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

.direct-link {
  min-width: 120px;
}

</style>
